<template>
	<div>
		<Header></Header>
		<div class="common-header">
			<div class="search-healder">
				<div class="logo-box">
					<img @click="toUrl('/')" class="logo" src="../../image/index/logo.png" />
				</div>
				<div class="search">
					<input placeholder="请输入商品名称" v-model="name" />
					<div class="search-btn el-icon-search" @click="search()"> </div>
				</div>
			</div>
		</div>
		<div class="cart-content">
			<div class="line" style="height: 40px;"></div>
			<div class="cart-laout">
				<div class="cart">
					<div class="cart-filter-bar">
						<span class="switch-cart">购物车（全部3）</span>
						<div class="cart-sum">
							<span class="pay-text">已选商品（不含运费）</span>
							<strong class="price">
								<em>
									<span class="total-symbol">&nbsp;</span>￥{{getPrice()}}
								</em>
							</strong>
							<a class="submit-btn-disabled" :class="getIsCan()?'submit-btn':''"
								@click="getIsCan()?settle():''">结&nbsp;算</a>
						</div>
					</div>
					<div class="cart-main">
						<div class="cart-table-th">
							<div class="wp">
								<div class="th th-chk">
									<div class="select-all" @click="selectAll()">
										<div class="cart-checkbox">
											<label :class="checkAll?'label-check':''">勾选购物车内所有商品</label>
										</div>
										&nbsp;&nbsp;全选
									</div>
								</div>
								<div class="th th-item">
									<div class="td-inner">商品信息</div>
								</div>
								<div class="th th-info">
									<div class="td-inner">&nbsp;</div>
								</div>
								<div class="th th-price">
									<div class="td-inner">单价</div>
								</div>
								<div class="th th-amount">
									<div class="td-inner">数量</div>
								</div>
								<div class="th th-sum">
									<div class="td-inner">金额</div>
								</div>
								<div class="th th-op">
									<div class="td-inner">操作</div>
								</div>
							</div>
						</div>
						<div class="orderlist" v-if="list.length>0">
							<div class="order" style="height:auto;">
								<div class="order-body">
									<div class="order-content">
										<div class="item-list" v-for="(item,index) in list" :key="index">
											<div class="bundle-last">
												<div class="item-holder">
													<div class="item-body clearfix item-normal first-item">
														<ul class="item-content">
															<li class="td td-chk">
																<div canplay="td-inner">
																	<div style="height: 82px;position: relative;">
																		<div class="cart-checkbox">
																			<label @click="item.isSelect=!item.isSelect"
																				:class="item.isSelect?'label-check':''">勾选商品</label>
																		</div>
																	</div>
																</div>
															</li>
															<li class="td td-item">
																<div class="td-inner">
																	<div class="item-pic">
																		<img :src="item.skuPic" />
																	</div>
																	<div class="item-info">
																		<div class="item-basic-info">
																			{{item.itemName}}
																		</div>
																	</div>
																</div>
															</li>
															<li class="td td-info">
																<div class="td-inner">
																	{{item.skuName}}
																</div>
															</li>
															<li class="td td-price">
																<div class="td-inner">
																	￥{{item.isPrice}}
																</div>
															</li>
															<li class="td td-amount">
																<div class="td-inner">
																	{{item.count}}
																</div>
															</li>
															<li class="td td-sum">
																<div class="td-inner">
																	￥{{item.count*item.isPrice}}
																</div>
															</li>
															<li class="td td-op">
																<div class="td-inner">
																	<div class="del" @click="del(item.id)">删除</div>
																</div>
															</li>
														</ul>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="float-bar-holder" v-if="list.length>0">
							<div class="float-bar">
								<div class="float-bar-wrapper">
									<div class="select-all" @click="selectAll()">
										<div class="cart-checkbox">
											<label :class="checkAll?'label-check':''">勾选购物车内所有商品</label>

										</div>
										&nbsp;全选
									</div>
									<div class="operations">
										<span class="delete" @click="batchDel()">删除</span>
									</div>
									<div class="float-bar-right">
										<div class="amount-sum">
											<span class="txt">已选商品</span>
											<em>{{getItemNum()}}</em>
											<span class="txt">件</span>
										</div>
										<div class="price-sum">
											<span class="txt">合计（不含运费）：</span>
											<strong class="price">
												<em>
													<span class="total-symbol">&nbsp;</span>￥{{getPrice()}}
												</em>
											</strong>
										</div>
										<div class="btn-area" :class="getIsCan()?'submit-btn':''"
											@click="getIsCan()?settle():''">
											结 算
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="line" style="height: 40px;"></div>
		</div>
		<Footer></Footer>

	</div>
</template>

<script>
	import {
		cartList,
		cartDel,
		cartBatchDel
	} from '@/api/member/member'
	import {
		getCookie,
		setCookie,
		delCookie
	} from '@/utils/auth'
	export default {
		data() {
			return {
				name: '',
				checkAll: false,
				list: []
			};
		},
		created() {
			this.getCartList()
		},
		methods: {
			search() {
				this.$router.push({
					path: '/item/search?name=' + this.name
				})
			},
			toUrl(url) {
				this.$router.push({
					path: url
				})
			},
			getCartList() {
				cartList({}).then(res => {
					var list = []
					var data = res.data
					data.forEach(item => {
						item.list.forEach(it => {
							list.push(it)
						})
					})
					this.list = list
				})
			},
			selectAll() {
				this.checkAll = !this.checkAll
				this.list.forEach(item => {
					item.isSelect = this.checkAll
				})
			},
			del(id) {
				cartDel({
					id: id
				}).then(res => {
					if (res.code == 200) {
						this.$message({
							message: '删除成功',
							type: 'success'
						});
						this.getCartList()
					}
				})
			},
			batchDel() {
				var ids = []
				this.list.forEach(item => {
					if (item.isSelect) {
						ids.push(item.id)
					}
				})
				if (ids.length == 0) {
					this.$message({
						message: '请选择要删除的商品',
						type: 'warning'
					});
					return
				}
				cartBatchDel({
					list: ids
				}).then(res => {
					if (res.code == 200) {
						this.$message({
							message: '删除成功',
							type: 'success'
						});
						this.getCartList()
						this.checkAll = false
					}
				})
			},
			getItemNum() {
				var num = 0
				this.list.forEach(item => {
					if (item.isSelect) {
						num += 1
					}
				})
				return num
			},
			getIsCan() {
				var can = false
				this.list.forEach(item => {
					if (item.isSelect) {
						can = true
					}
				})
				return can
			},
			getPrice() {
				var price = 0
				this.list.forEach(item => {
					if (item.isSelect) {
						price += (item.isPrice * 100 * item.count)
					}
				})
				return price / 100
			},
			settle() {
				var data = [{
					shopId: this.list[0].shopId,
					useIntegral: 0,
					itemList: []
				}]
				var cartIds = []
				this.list.forEach(item => {
					if (item.isSelect) {
						data[0].itemList.push({
							skuId: item.skuId,
							count: item.count,
							couponId: null
						})
						cartIds.push(item.id)
					}
				})
				if (cartIds.length > 0) {
					setCookie("cartIds", JSON.stringify(cartIds))
				}
				var param = JSON.stringify(data)
				setCookie("orderItem", param)
				this.$router.push({
					name: 'orderConfirm',
					params: {
						param: param,
						cartIds: cartIds
					}
				})
			}
		},
	};
</script>

<style lang='scss'>
	.cart-content {
		background-color: #f4f4f4;

		.cart-laout {
			min-height: 400px;
			margin-left: 10%;
			width: 80%;
			background-color: #ffffff;
			-webkit-border-radius: 24px;
			-moz-border-radius: 24px;
			-ms-border-radius: 24px;
			border-radius: 24px;

			.cart {
				overflow: hidden;

				.cart-filter-bar {
					overflow: hidden;
					font-size: 12px;
					position: relative;
					height: 72px;
					padding: 0 18px;
					border-bottom: 1px solid #e6e6e6;

					.switch-cart {
						overflow: hidden;
						color: #000;
						font-size: 18px;
						font-weight: 600;
						height: 72px;
						line-height: 72px;
					}

					.cart-sum {
						position: absolute;
						right: 18px;
						top: 0;
						height: 72px;
						line-height: 72px;
						font-size: 14px;

						.pay-text {
							position: relative;
							top: -2px;
						}

						.price {
							font-family: Arial, Verdana;
							font-weight: 400;
							margin-right: 12px;
							font-size: 22px;
							color: #FF5000;

							em {
								font-family: Verdana, Arial;
								padding-left: 2px;
								font-weight: bold;
								font-size: 22px;

								.total-symbol {
									font-size: 12px;
									font-weight: 400;
								}
							}
						}

						.submit-btn-disabled {
							background: #aaa;
							color: #fff;
							cursor: not-allowed;
							display: inline-block;
							width: 74px;
							height: 42px;
							line-height: 42px;
							color: #fff;
							-webkit-border-radius: 21px;
							-moz-border-radius: 21px;
							-ms-border-radius: 21px;
							border-radius: 21px;
							text-align: center;
							text-decoration: none;
							position: relative;
							top: -2px;
							font-size: 16px;
						}

						.submit-btn {
							background: #FF5000;
							color: #fff;
							cursor: pointer;
						}
					}
				}

				.cart-main {
					min-height: 210px;

					.cart-table-th {
						overflow: hidden;
						height: 50px;
						line-height: 50px;
						color: #3c3c3c;
						font-weight: 700;

						.wp {
							margin: 0 auto;
							width: 90%;
							margin-left: 5%;

							.th {
								float: left;

								.td-inner {
									font-size: 13px;
								}
							}

							.th-chk {
								font-size: 12px;
								text-align: left;
								position: relative;
								height: 50px;

								.select-all {
									cursor: pointer;
									position: absolute;
									left: 8px;
									top: 0;
									width: 80px;
									font-size: 13px;

									.cart-checkbox {
										margin: -1px 0 0 15px;
										margin-top: 0px;

										input {
											position: absolute;
											z-index: 0;
											left: -20px;
											top: -20px;
										}

										label {
											position: relative;
											z-index: 1;
											float: left;
											width: 18px;
											height: 18px;
											background-position: 0 0;
											line-height: 200em;
											overflow: hidden;
											cursor: pointer;
											background-image: url(../../image/cart/label.png);
											background-repeat: no-repeat;
											background-size: cover;
											margin-top: 16px;
										}

										.label-check {
											background-image: url(../../image/cart/label-select.png);
										}
									}
								}
							}

							.th-item {
								width: 342px;

								.td-inner {
									padding-left: 117px;
								}
							}

							.th-info {
								width: 212px;
								padding-right: 42px;

								.td-inner {
									padding: 0 0 0 10px;
									font-size: 13px;
								}
							}

							.th-price {
								width: 130px;

								.td-inner {
									padding: 0 0 0 10px;
									font-size: 13px;
								}
							}

							.th-amount {
								width: 120px;

								.td-inner {
									padding: 0 0 0 10px;
									font-size: 13px;
								}
							}

							.th-sum {
								width: 140px;

								.td-inner {
									padding: 0;
								}
							}

							.th-op {
								width: 72px;
								padding: 0 0 0 30px;

								.td-inner {
									padding: 0;
								}
							}
						}
					}

					.order-body {
						margin-bottom: 15px;

						.order-content {
							border: 1px solid #F5F5F5;
							background-color: #F5F5F5;
							-webkit-border-radius: 18px;
							-moz-border-radius: 18px;
							-ms-border-radius: 18px;
							border-radius: 18px;
							margin: 0 24px;
							overflow: hidden;
							padding-left: 20px;
							padding-top: 20px;

							.bundle-last {
								overflow: hidden;
								border-bottom: 0;

								ul {
									list-style: none;
								}

								.item-holder {
									position: relative;

									.item-content {
										padding-right: 12px;

										.td {
											float: left;
											min-height: 119px;
										}

										.td-chk {
											position: relative;
											overflow: hidden;

											.cart-checkbox {
												position: relative;
												display: inline-block;
												width: 18px;
												height: 18px;
												overflow: hidden;
												margin: 1px 10px 0 0;
												float: right;

												label {
													position: relative;
													z-index: 1;
													float: left;
													width: 15px;
													height: 15px;
													background-position: 0 0;
													line-height: 200em;
													overflow: hidden;
													cursor: pointer;
													background-image: url(../../image/cart/label.png);
													background-repeat: no-repeat;
													background-size: cover;
												}

												.label-check {
													background-image: url(../../image/cart/label-select.png);
												}
											}
										}

										.td-item {
											width: 342px;

											.td-inner {
												padding-top: 20px;

												.item-pic {
													background: #fff;
													width: 80px;
													height: 80px;
													-webkit-border-radius: 3px;
													-moz-border-radius: 3px;
													-ms-border-radius: 3px;
													border-radius: 3px;
													border: 1px solid #eee;
													float: left;
													overflow: hidden;

													img {
														height: 80px;
														width: 80px;
														vertical-align: middle;
													}
												}

												.item-info {
													margin: -3px 0 0 91px;
													padding-right: 12px;

													.item-basic-info {
														font-size: 14px;
														display: block;
														max-height: 36px;
														overflow: hidden;
														text-overflow: ellipsis;
														color: #3c3c3c;
													}
												}
											}
										}

										.td-info {
											width: 212px;
											padding-right: 8px;
											font-size: 14px;

											.td-inner {
												padding: 16px 0;
												min-height: 84px;
												border: 1px dashed transparent;
												position: relative;
												margin: 0 5px 0 15px;
												color: #9c9c9c;
												overflow: hidden;
												text-overflow: ellipsis;
											}
										}

										.td-price {
											width: 130px;

											.td-inner {
												padding: 0 0 4px 30px;
												color: #3c3c3c;
												font-weight: 700;
												font-size: 14px;
											}
										}

										.td-amount {
											width: 120px;

											.td-inner {
												padding: 0 0 0 50px;
												font-size: 14px;
											}
										}

										.td-sum {
											width: 140px;

											.td-inner {
												padding: 0 0 0 40px;
												color: #FF5000;
												font-weight: bold;
												font-size: 14px;
											}
										}

										.td-op {
											width: 72px;
											padding: 0 0 0 30px;

											.td-inner {
												padding: 0 0 0 40px;
												cursor: pointer;

												.del:hover {
													color: #FF5000;
													text-decoration: underline;
												}
											}
										}
									}
								}
							}
						}
					}

					.float-bar-holder {
						position: relative;
						height: 72px;
						background-color: #fff;
						border-top: 1px solid #e6e6e6;

						.float-bar {
							position: static;
							margin: 0 auto;

							.float-bar-wrapper {
								height: 72px;
								overflow: hidden;
								margin: 0 24px;

								.select-all {
									float: left;
									width: 62px;
									height: 72px;
									line-height: 72px;
									padding-left: 5px;
									font-size: 13px;
									cursor: pointer;

									.cart-checkbox {
										vertical-align: middle;
										margin-top: -2px;
										position: relative;
										display: inline-block;
										width: 18px;
										height: 18px;
										overflow: hidden;

										label {
											position: relative;
											z-index: 1;
											float: left;
											width: 18px;
											height: 18px;
											background-position: 0 0;
											line-height: 200em;
											overflow: hidden;
											cursor: pointer;
											background-image: url(../../image/cart/label.png);
											background-repeat: no-repeat;
											background-size: cover;
										}

										.label-check {
											background-image: url(../../image/cart/label-select.png);
										}
									}
								}

								.operations {
									float: left;
									line-height: 72px;
									height: 72px;
									font-size: 13px;

									span {
										margin-left: 25px;
										float: left;
										cursor: pointer;
									}

									span:hover {
										color: #f40;
										text-decoration: underline;

									}
								}

								.float-bar-right {
									float: right;
									position: absolute;
									right: 18px;
									top: 15px;
									z-index: 4;
									padding-left: 20px;

									.amount-sum {
										cursor: pointer;
										height: 48px;
										color: #3c3c3c;
										float: left;

										.txt {
											float: left;
											line-height: 48px;
											font-size: 13px;
											color: #000;
										}

										em {
											line-height: 50px;
											padding: 0 5px;
											font-size: 20px;
											float: left;
											color: #f40;
											font-weight: 700;
										}
									}

									.price-sum {
										margin-right: 7px;
										margin-left: 7px;
										eight: 48px;
										color: #3c3c3c;
										float: left;

										.txt {
											float: left;
											line-height: 48px;
											font-size: 13px;
											color: #000;
										}

										.price {
											float: left;
											color: #f40;
											font-weight: 400;
											font-size: 18px;
											line-height: 48px;
											font-family: Arial;
											vertical-align: middle;

											em {
												font-weight: 700;
												font-size: 22px;
												padding: 0 3px;
												color: #f40;

												.total-symbol {
													font-size: 14px;
													color: #f40;
													font-weight: bold;
												}
											}
										}
									}

									.btn-area {
										float: left;
										cursor: not-allowed;
										text-decoration: none;
										display: inline-block;
										width: 74px;
										height: 42px;
										line-height: 42px;
										color: #fff;
										background: #B0B0B0;
										border-radius: 21px;
										text-align: center;
									}

									.submit-btn {
										background: #FF5000;
										color: #fff;
										cursor: pointer;
									}
								}
							}
						}
					}
				}
			}
		}
	}
</style>